<template>
    <div>
        <fromSearch :object_='collection_1' @searchFun='getList' />

        <el-table :data="page.list" border v-loading='listLoading' height="300">
            <el-table-column prop="selection" :label="$t('currencySetNft.text8')" width="80" >
                <template #default="scope">
                    <el-checkbox size="large" @change="changeFun(scope.row)" v-model='checkItem'/>
                </template>
            </el-table-column>
            <el-table-column prop="chain" :label="$t('currencySet.text10')" />
            <el-table-column prop="protocol" :label="$t('currencySet.text11')" />
            <el-table-column prop="tenantName" :label="$t('currencySetNft.name1')" width="200" />
            <el-table-column prop="tokenId" label="Token ID" />
            <el-table-column prop="appName" :label="$t('currencySetNft.name2')" />
            <el-table-column prop="collectionName" :label="$t('nfts.text13')" />
            <el-table-column prop="itemName" :label="$t('withdrawNft.name11')" />
            <el-table-column prop="totalNum" :label="$t('nfts.name19')" width='100'/>
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineEmits,defineExpose } from 'vue'
    // import { collection } from "@/const/crud/cryptocurrency/customerWithdrawalArr";
    import mixins from '@/views/mixins/page'
    import { collection_ } from '@/const/from/nft/nftWithdrawUser'
    import fromSearch from '@/components/fromSearch.vue'
    import pageCom from '@/components/pageCom.vue'

    let pageObj = mixins(['getPageByGroup'])
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj
    const checkItem = ref(false)
    
    const collection_1 = ref(collection_);
    const emit = defineEmits(['getCoin1'])



    const changeFun = (e)=>{
        emit('getCoin1',e)
    }
    const init_ = (e)=>{
        checkItem.value = false
        updateOtherParm(e)
        getList()
    }
    defineExpose({init_})
    
</script>

<style lang="scss" scoped>
</style>