<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass" label-position="top" :rules='rules'>
                    <el-form-item :label="$t('currencySet.name1')"  v-if='isPlatform'>
                        <el-select v-model="fromValue_.tenantId" :placeholder="$t('inputTit.xuanze')" @change="getItem('tenantId')" :teleported='false'>
                            <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name2')">
                        <el-select v-model="fromValue_.appId" :placeholder="$t('inputTit.xuanze')" @change="getItem('appId')" :teleported='false'>
                            <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'  />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('withdrawNft.name2')">
                        <el-radio-group v-model="fromValue.protocolType" size="large" @change="cheseItem">
                            <el-radio-button :label="2">NFT</el-radio-button>
                            <el-radio-button :label="1">{{$t('withdraw.text5')}}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="textArray[fromValue.protocolType]" prop="chain_protocol_coin" v-if='fromValue.protocolType === 1'>
                        <el-input v-model="fromValue.chain_protocol_coin" disabled :placeholder="$t('inputTit.xuanze')">
                            <template #append>
                                <el-button text type="primary" @click="chooseCoin">{{$t('button.checks')}}</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="textArray[fromValue.protocolType]" prop="collectionName_itemName" v-else>
                        <el-input v-model="fromValue.collectionName_itemName" disabled :placeholder="$t('inputTit.xuanze')">
                            <template #append>
                                <el-button text type="primary" @click="chooseCoin">{{$t('button.checks')}}</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('withdrawNft.name1')" prop="toAddress">
                        <el-input v-model="fromValue.toAddress" :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('withdrawNft.name4')" prop="applyReason">
                        <el-input v-model="fromValue.applyReason" :placeholder="$t('inputTit.shuru')" type='textarea'/>
                    </el-form-item>
                    <el-form-item :label="fromValue.protocolType === 2?'提现数量':'提现金额'" prop="amount">
                        <el-input v-model="fromValue.amount"  :placeholder="$t('inputTit.xuanze')"><template #suffix>
                                <div>
                                    {{fromValue.protocolType === 2?'可用数量':'可用余额'}}：{{amount_}}
                                </div>
                            </template></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('currencySetNft.name29')" prop="googleCode">
                        <el-input v-model="fromValue.googleCode"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>

            <el-row :gutter="24" justify="end">
                <el-col :span="8" >
                    <el-button @click="emit('successFun')">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.enter')}}</el-button>
                </el-col>
            </el-row>
        </el-form>
         <el-dialog v-model="innerVisible1" width="60%" :title="textArray[fromValue.protocolType]" append-to-body>
            <collectionView @getCoin1='getCoin1' ref='collectionView_' v-if='fromValue.protocolType === 2'/>
            <addrView @getCoin1='getCoin1' ref='addrView_' v-if='fromValue.protocolType === 1'/>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,reactive,nextTick,defineEmits,getCurrentInstance } from 'vue'
    import { userStore } from '@/store/user'
    import addrView from './addr.vue'
    import collectionView from './collection.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import { randomCoding } from '@/utils/util.js'
    import apiList from '@/const/apiList'
    
    const fromValue = reactive({ 
        applyId: '',amount: '',applyUser:'',
        applyUserType:2,
        chain:'',coin:'',fromAddress:'',fromWalletId:'',protocol:'',
        toAddress: '',chain_protocol_coin:'',
        transactionId: randomCoding(64),
        protocolType: 2,applyReason:'',
        tokenId:'',collectionName_itemName:'',contractAddress:''
    })
    const amount_ = ref(null)
    const fromValue_ = reactive({
        userId:'',appId:'',tenantId:''
    })
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.applyUser = userStore().getUserInfo[0].username;
    fromValue_.tenantId = userStore().getUserInfo[0].tenantId;
    // fromValue.userId = userStore().getUserInfo[0].userId;
    // fromValue
    const emit = defineEmits(['successFun'])
    const tenantArray = ref([])
    const appArray = ref([]); 
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;},fromValue.tenantId) 

    const innerVisible1 = ref(false)
    const form_ = ref(null)
    const rules = ref(null);
    rules.value = addRules.mainRules
    const addrView_ = ref(null)
    const collectionView_ = ref(null)
    let textArray = ['',$t('nfts.text11'),$t('nfts.text12')]
    
    const cheseItem = ()=>{//选择切换nft和代币
        amount_.value = ''
        if(fromValue.protocolType === 1){//nft,删除对应的表单验证
            rules.value.chain_protocol_coin = [{ required: true, message: $t('inputTit.xuanze'), trigger: 'blur' }]
            delete rules.value.chain_protocol_coin
        }else{
            rules.value.collectionName_itemName = [{ required: true, message: $t('inputTit.xuanze'), trigger: 'blur' }]
            delete rules.value.collectionName_itemName
        }
    }
    const chooseCoin = ()=>{
        if(!fromValue_.tenantId || !fromValue_.appId){
            ElNotification({
                message: $t('nfts.paragraph2'),
                type: 'warning',
            })
        }else{
            innerVisible1.value = true
            nextTick(()=>{
                let obj_ = {
                    tenantId: fromValue_.tenantId,
                    appId: fromValue_.appId,
                }
                if(fromValue.protocolType === 1){
                    obj_.walletType = 5
                    addrView_.value.init_(obj_)
                }else{
                    obj_.nftOwnerType = 2
                    collectionView_.value.init_(obj_)
                }
                
            })
        }
    }
    const getItem = (str)=>{//选项框的联动设置
        if(str === 'tenantId'){
            fromValue_.appId = ''
            apiList.getAppList((data)=>{appArray.value = data;},fromValue_.tenantId)
        }
        fromValue.chain_protocol_coin = ''
    }
    const completeFun = (form_)=>{
        if (!form_) return
        form_.validate((e) => {
            if(e){
                let obj_ = JSON.parse(JSON.stringify(fromValue))
                if(fromValue.protocolType === 1){
                    delete obj_.tokenId
                }else{
                    delete obj_.coin
                }
                delete obj_.collectionName_itemName
                delete obj_.chain_protocol_coin
                obj_.appId = fromValue_.appId
                obj_.tenantId = fromValue_.tenantId
                Api_.addCustomerWithdrawal(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        })
    }
    const getCoin1 = (obj)=>{
        if(fromValue.protocolType === 1){
            fromValue.chain = obj.chain;
            fromValue.coin = obj.coin;
            fromValue.protocol = obj.protocol;
            fromValue.fromAddress = obj.walletAddress;
            fromValue.fromWalletId = obj.walletId;
            fromValue.chain_protocol_coin = obj.chain + '-' + obj.walletName + '-' + obj.coin;
            amount_.value = obj.amount
        }else{
            fromValue.chain = obj.chain;
            fromValue.protocol = obj.protocol;
            fromValue.contractAddress = obj.contractAddress;
            fromValue.tokenId = obj.tokenId
            fromValue.fromAddress = obj.nftOwnerAddr
            fromValue.fromWalletId = obj.ownnerWalletId
            fromValue.collectionName_itemName = obj.collectionName + '-' + obj.itemName
            amount_.value = obj.totalNum
        }
        innerVisible1.value = false;
    }
</script>